<template>
  <v-dialog v-model="opened" persistent max-width="400">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-bind="attrs" v-on="on">
        <v-list-item-title>Delete</v-list-item-title>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title class="headline"> Delete {{ objectName }} </v-card-title>
      <v-card-text>
        Are you sure you would like to delete this
        {{ objectTitle }}?</v-card-text
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="gray darken-1" text @click="close">
          Cancel
        </v-btn>
        <v-btn color="red darken-1" :loading="loading" text @click="onDelete">
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mdiDelete } from '@mdi/js'

export default {
  data() {
    return {
      icons: { mdiDelete }
    }
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    objectName: {
      type: String,
      default: 'objectName prop text missing'
    },
    objectTitle: {
      type: String,
      default: 'objectTitle prop missing'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onDelete() {
      this.$emit('onDelete')
      this.close()
    },
    close() {
      this.dialog = false
    }
  },
  computed: {
    opened: {
      get() {
        return this.dialog
      },
      set(nVal) {
        this.dialog = nVal
      }
    }
  }
}
</script>
