<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Venues ({{ listMeta.total }})</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-row v-if="showSearch" align="center" style="max-width: 300px;">
        <v-text-field
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          placeholder="Search name, type, address, Staff email, tags"
          v-model="filter.search"
          @input="search"
        ></v-text-field>
      </v-row>
      <template>
        <v-btn class="ml-5" icon @click="showSearch = !showSearch">
          <img src="@/assets/icons/search.svg" alt height="17.7px" />
        </v-btn>
      </template>
    </v-app-bar>
    <div class="row">
      <v-col cols="7">
        <v-btn
          :to="{ name: 'venues.new' }"
          depressed
          rounded
          color="success"
          class="pl-3"
          :loading="loading"
        >
          <v-icon>{{ icons.add }}</v-icon>
          New
        </v-btn>
        <v-btn
          @click="exportList"
          :loading="exportLoading || loading"
          depressed
          rounded
          dark
          color="blue-grey"
          class="pl-3 ml-2"
        >
          <v-icon>{{ icons.export }}</v-icon>
          Export
        </v-btn>
      </v-col>
      <v-col cols="2" class="pr-0">
        <v-subheader class="float-right">Sort By</v-subheader>
      </v-col>
      <v-col sm="3" class="pl-0 pb-0">
        <v-select
          v-model="defaultFilter"
          class="float-left pb-0 pt-2"
          :items="filters"
          dense
          outlined
          @change="sortVenues()"
        ></v-select>
      </v-col>
    </div>
    <div
      class="mt-12 mx-4"
      id="venue-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <v-simple-table>
        <thead>
          <tr>
            <th class="text-left">Name</th>
            <th class="text-left">Type</th>
            <th class="text-left">Staff Email</th>
            <th class="text-left">Tags</th>
            <th class="text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="venue in venues" :key="venue.id" class="clickable">
            <td class="py-5">
              <router-link :to="'venues/' + venue.id">{{
                venue.name
              }}</router-link>
            </td>
            <td class="py-5">{{ venue.type.name }}</td>
            <td class="py-5">{{ venue.staff_email }}</td>
            <td>
              <v-chip
                class="ma-2"
                color="primary"
                v-for="tag in venue.tags"
                v-bind:key="tag.tag"
                small
                >{{ tag.tag }}</v-chip
              >
            </td>
            <td class="py-5">
              <action-buttons
                :item="venue"
                @onDelete="onDelete"
              ></action-buttons>
            </td>
          </tr>
          <template v-if="loading">
            <tr v-for="index in 10" :key="index + '-skeleton'">
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>
    </div>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      right
    >
      <v-skeleton-loader
        type="text"
        v-if="snackbar.loading"
      ></v-skeleton-loader>
      {{ snackbar.message }}
      <v-icon
        transition="scale-transition"
        v-if="snackbar.color == 'success'"
        >{{ icons.check }}</v-icon
      >
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiPlus, mdiFileExcel } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import ActionButtons from '@/views/Home/Venues/actionButtons'

export default {
  name: 'VenuesPage',

  mixins: [ControlsMixin],

  directives: {
    infiniteScroll
  },

  components: {
    AppBarNavIcon,
    ActionButtons
  },

  data() {
    return {
      loading: false,
      exportLoading: false,
      showSearch: false,
      showProfile: false,
      selectedProfile: null,
      filter: {
        search: ''
      },
      defaultFilter: {
        text: 'Date Created',
        value: '-created_at'
      },
      filters: [
        {
          text: 'Newest',
          value: '-created_at'
        },
        {
          text: 'Oldest',
          value: 'created_at'
        },
        {
          text: 'Name desc',
          value: '-name'
        },
        {
          text: 'Name asc',
          value: 'name'
        }
      ],
      icons: {
        add: mdiPlus,
        export: mdiFileExcel
      },
      snackbar: {
        show: false,
        message: null,
        color: null,
        timeout: -1,
        loading: false
      }
    }
  },

  created() {
    this.clearVenues()
    this.fetchVenues(1)
  },

  computed: {
    ...mapState({
      venues: state => state.venue.list,
      listMeta: state => state.venue.listMeta
    }),

    paginationLength() {
      return (this.listMeta.total / this.listMeta.per_page) | 0
    }
  },

  methods: {
    ...mapActions({
      getVenues: 'venue/getVenues',
      deleteVenue: 'venue/deleteVenue',
      exportToExcel: 'venue/exportToExcel'
    }),

    ...mapMutations({
      clearVenues: 'venue/clearVenueList'
    }),

    pageChanged(page) {
      this.fetchVenues(page)
    },

    loadMore() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchVenues(this.listMeta.current_page + 1)
      }
    },

    search: debounce(function() {
      this.clearVenues()
      this.fetchVenues()
    }, 600),

    sortVenues() {
      this.clearVenues()
      this.fetchVenues()
    },

    async fetchVenues(page = 1) {
      if (this.loading) return
      let params = { page }
      if (this.filter.search) {
        params.search = this.filter.search
      }
      params.sort = this.defaultFilter.value
        ? this.defaultFilter.value
        : this.defaultFilter

      this.loading = true
      await this.getVenues(params)
      this.loading = false
    },

    async onDelete(id, index) {
      this.snackbar.timeout = -1
      this.snackbar.loading = true
      this.showSnackbar('Deleting venue', 'primary')
      await this.deleteVenue(id)
      this.snackbar.loading = false
      this.snackbar.timeout = 1000
      this.showSnackbar('Venue deleted', 'success')
      this.fetchVenues()
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.color = color
      this.snackbar.show = true
    },

    async exportList() {
      this.exportLoading = true
      let params = {}
      params.search = this.filter.search
      params.sort = this.defaultFilter.value
        ? this.defaultFilter.value
        : this.defaultFilter

      await this.exportToExcel(params)
        .then(response => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel'
            })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'venues_list.xlsx')
          document.body.appendChild(link)
          link.click()
          this.showSnackbar('File successfully downloaded', 'success')
        })
        .catch(() => {
          this.showSnackbar('Ops! Something went wrong', 'red')
        })
      this.exportLoading = false
    }
  }
}
</script>
