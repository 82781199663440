<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <v-toolbar-title class="headline font-weight-bold">
        Staff ({{ listMeta.total }})
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-row v-if="showSearch" align="center" style="max-width: 300px;">
        <v-text-field
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          placeholder="Search email, venue name"
          v-model="filter.search"
          @input="search"
        ></v-text-field>
      </v-row>

      <v-skeleton-loader
        type="actions"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <template v-else>
        <v-btn class="ml-5" icon @click="showSearch = !showSearch">
          <img src="@/assets/icons/search.svg" alt height="17.7px" />
        </v-btn>
      </template>
    </v-app-bar>

    <div
      class="mt-12 mx-4"
      id="user-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <v-simple-table>
        <thead>
          <tr>
            <th width="25%" class="text-left">Email</th>
            <th width="25%" class="text-left">Venue</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="user in staffs" :key="user.id">
            <td class="py-5">
              <router-link :to="'users/' + user.id">
                {{ user.email }}
              </router-link>
            </td>
            <td class="py-5">
              <span v-if="user.venue">
                <router-link :to="'venues/' + user.venue.id">
                  {{ user.venue.name }}
                </router-link>
              </span>
            </td>
          </tr>

          <template v-if="loading">
            <tr v-for="index in 10" :key="index + '-skeleton'">
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>
    </div>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" right>
      {{ snackbar.message }}
      <v-btn color="white" text @click="snackbar.show = false">Ok</v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import {
  mdiDotsVertical,
  mdiUnfoldMoreHorizontal,
  mdiMagnify,
  mdiAccountCircleOutline,
  mdiChevronRight
} from '@mdi/js'

import { mapState } from 'vuex'
import debounce from 'lodash/debounce'
import infiniteScroll from 'vue-infinite-scroll'
import Staff from '@/models/Staff'
import { each } from 'lodash'

export default {
  directives: {
    infiniteScroll
  },
  data() {
    return {
      showSearch: false,
      filter: {
        search: ''
      },
      loading: false,
      icons: {
        settings: mdiDotsVertical,
        sort: mdiUnfoldMoreHorizontal,
        search: mdiMagnify,
        view: mdiAccountCircleOutline,
        arrow: mdiChevronRight
      },
      staffs: [],
      snackbar: {
        show: false,
        message: null,
        color: null
      },
      listMeta: {
        current_page: 0,
        last_page: 1
      }
    }
  },
  computed: {
    ...mapState({
      users: state => state.user.list
    })
  },
  methods: {
    loadMore() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchUsers(this.listMeta.current_page + 1)
      }
    },
    async getStaffs(params) {
      const qry = Staff.page(params.page)
      if (params.search) {
        qry.where('search', params.search)
      }
      await qry.get().then(d => {
        this.listMeta = d.meta
        each(d.data, staff => {
          const exist = find(this.staffs, { id: staff.id })
          if (!exist) {
            this.staffs.push(new Staff(staff))
          }
        })
      })
    },
    search: debounce(function() {
      this.staffs = []
      this.fetchUsers()
    }, 600),

    async fetchUsers(page = 1) {
      if (this.loading) return
      let params = { page }
      if (this.filter.search) {
        params.search = this.filter.search
      }
      this.loading = true
      await this.getStaffs(params)
      this.loading = false
    },
    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.color = color
      this.snackbar.show = true
    }
  }
}
</script>
