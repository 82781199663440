<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <v-toolbar-title class="headline font-weight-bold">Users</v-toolbar-title>
    </v-app-bar>
    <v-tabs v-model="tab">
      <v-tabs-slider color="primary"></v-tabs-slider>

      <v-tab :key="1">
        Customers
      </v-tab>

      <v-tab :key="2">
        Staff
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item :key="0">
        <v-card flat>
          <v-card-text>
            <customers />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item :key="1">
        <v-card flat>
          <v-card-text>
            <staff />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import Customers from '@/views/Home/Users/Customer/index'
import Staff from '@/views/Home/Users/Staff'
export default {
  data() {
    return {
      tab: 0
    }
  },
  components: {
    Customers,
    Staff
  }
}
</script>
