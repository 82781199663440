<template>
  <v-menu offset-y left :close-on-content-click="true" v-model="open">
    <template v-slot:activator="{ on }">
      <v-btn class="ma-0" small depressed fab dark :color="color" v-on="on">
        <v-icon large>{{ icons.dots }}</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item :to="'/venues/' + item.id + '/edit'">
        <v-list-item-title>Edit</v-list-item-title>
      </v-list-item>
      <delete-modal
        :objectName="item.name"
        objectTitle="venue"
        :loading="loading"
        :dialog="deleteModal"
        @onDelete="onDeleteVenue(item.id)"
      ></delete-modal>
    </v-list>
  </v-menu>
</template>

<script>
import { mdiDotsHorizontal } from '@mdi/js'
import { mapActions } from 'vuex'
import DeleteModal from '@/components/modals/delete'

export default {
  data() {
    return {
      loading: false,
      open: false,
      deleteModal: false,
      icons: {
        dots: mdiDotsHorizontal
      }
    }
  },
  components: {
    DeleteModal
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    color: {
      type: String,
      default: 'grey'
    }
  },
  methods: {
    ...mapActions({
      delete: 'venue/deleteVenue'
    }),
    async onDeleteVenue(id) {
      this.$emit('onDelete', id)
    }
  }
}
</script>
