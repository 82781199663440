<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <v-toolbar-title class="headline font-weight-bold">
        Customers ({{ listMeta.total }})
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-row v-if="showSearch" align="center" style="max-width: 300px;">
        <v-text-field
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          placeholder="Search email or phone number"
          v-model="filter.search"
          @input="search"
        ></v-text-field>
      </v-row>

      <v-skeleton-loader
        type="actions"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <template v-else>
        <v-btn class="ml-5" icon @click="showSearch = !showSearch">
          <img src="@/assets/icons/search.svg" alt height="17.7px" />
        </v-btn>
      </template>
    </v-app-bar>

    <div class="row">
      <v-col cols="7">
        <v-btn
          @click="exportList"
          :loading="exportLoading || loading"
          depressed
          rounded
          dark
          color="blue-grey"
          class="pl-3 ml-2"
        >
          <v-icon>{{ icons.export }}</v-icon>
          Export
        </v-btn>
      </v-col>
    </div>

    <div
      class="mt-12 mx-4"
      id="user-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <v-simple-table>
        <thead>
          <tr>
            <th width="25%" class="text-left">Email</th>
            <th width="25%" class="text-left">Phone number</th>
            <th width="25%" class="text-left">Date joined</th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="user in customers"
            :key="user.id"
            class="clickable"
            @click="
              $router.push({ name: 'user.details', params: { id: user.id } })
            "
          >
            <td class="py-5">{{ user.email }}</td>
            <td class="py-5">
              {{ user.phone_number ? '+' + user.phone_number : '' }}
            </td>
            <td class="py-5">{{ user.addedDate }}</td>
          </tr>

          <template v-if="loading">
            <tr v-for="index in 10" :key="index + '-skeleton'">
              <td class="py-3 pl-0" width="5%">
                <v-skeleton-loader
                  class="avatar-loading--36"
                  type="avatar"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>
    </div>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" right>
      {{ snackbar.message }}
      <v-btn color="white" text @click="snackbar.show = false">Ok</v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import {
  mdiDotsVertical,
  mdiUnfoldMoreHorizontal,
  mdiMagnify,
  mdiAccountCircleOutline,
  mdiChevronRight,
  mdiFileExcel
} from '@mdi/js'

import { mapState, mapActions } from 'vuex'
import debounce from 'lodash/debounce'
import infiniteScroll from 'vue-infinite-scroll'
import Customer from '@/models/Customer'
import { each } from 'lodash'

export default {
  directives: {
    infiniteScroll
  },
  data() {
    return {
      showSearch: false,
      filter: {
        search: ''
      },
      loading: false,
      exportLoading: false,
      icons: {
        settings: mdiDotsVertical,
        sort: mdiUnfoldMoreHorizontal,
        search: mdiMagnify,
        view: mdiAccountCircleOutline,
        arrow: mdiChevronRight,
        export: mdiFileExcel
      },
      customers: [],
      snackbar: {
        show: false,
        message: null,
        color: null
      },
      listMeta: {
        current_page: 0,
        last_page: 1,
        total: 0
      }
    }
  },
  computed: {
    ...mapState({
      users: state => state.user.list
    })
  },
  methods: {
    ...mapActions({
      exportToExcel: 'user/exportCustomersToExcel'
    }),

    loadMore() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchUsers(this.listMeta.current_page + 1)
      }
    },
    async getCustomers(params) {
      const qry = Customer.page(params.page).include('avatar')
      if (params.search) {
        qry.where('search', params.search)
      }
      await qry.get().then(d => {
        this.listMeta = d.meta
        each(d.data, customer => {
          const exist = find(this.customers, { id: customer.id })
          if (!exist) {
            this.customers.push(new Customer(customer))
          }
        })
      })
    },
    search: debounce(function() {
      this.customers = []
      this.fetchUsers()
    }, 600),

    async fetchUsers(page = 1) {
      if (this.loading) return
      let params = { page }
      if (this.filter.search) {
        params.search = this.filter.search
      }
      this.loading = true
      await this.getCustomers(params)
      this.loading = false
    },
    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.color = color
      this.snackbar.show = true
    },
    async exportList() {
      this.exportLoading = true
      let params = {}
      params.search = this.filter.search
      // params.sort = this.defaultFilter.value
      //   ? this.defaultFilter.value
      //   : this.defaultFilter

      await this.exportToExcel(params)
        .then(response => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel'
            })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'customers_list.xlsx')
          document.body.appendChild(link)
          link.click()
          this.showSnackbar('File successfully downloaded', 'success')
        })
        .catch(() => {
          this.showSnackbar('Ops! Something went wrong', 'red')
        })
      this.exportLoading = false
    }
  }
}
</script>
